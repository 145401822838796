import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import SmoltingClassic from './svg/SmoltingClassic';
import Tukinowagamo from './svg/Tukinowagamo';
import Tukino from './svg/Tukino';


interface Props {
  title: string;
  p1: string;
  p2: string;
  p3: string;
  p4: string;
}

const style = {
  backgroundColor: "#bb90e9",
}

const About = ({ title, p1, p2, p3, p4 }: Props) => {
  return (
    <div style={style}>
      <hr className="blurred" />
      <Container sx={{ py: 8}}>
        <Typography
          id="__ABOUT"
          className='text-meme title-section'
          component="h2"
          variant="h1"
          align="center"
          color="#fff"
          fontFamily={"Londrina"}
          gutterBottom
        >
          { title }
        </Typography>
        <AnimationOnScroll animateIn="animate__bounceInUp">
          <Typography className="text-meme" align="center" variant="h4" color="#fff" paragraph>
            {p1}
          </Typography>
        </AnimationOnScroll>
        <Accordion sx={{backgroundColor:"#DAB8FF", color: "#000"}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Stack 
              sx={{textAlign: "center", margin:"auto"}}
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}>
              <AnimationOnScroll animateIn="animate__rubberBand">
                <div style={{textAlign: "center", margin:"auto"}}>
                  <Tukino></Tukino>
                </div>
              </AnimationOnScroll>
              <Typography component="h4" variant="h4" sx={{textAlign:"center", lineHeight: 3}} > 
                Watafak is a wassie?
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h5" color="text.secondary" paragraph>
              {p2}
            </Typography>
            <Typography variant="h5" color="text.secondary" paragraph>
              {p3}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <br />
        {/* History */}
        <Accordion sx={{backgroundColor:"#DAB8FF", color: "#000"}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Stack 
              sx={{textAlign: "center", margin:"auto"}}
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}>
              <AnimationOnScroll animateIn="animate__rubberBand">
                <div style={{textAlign: "center", margin:"auto"}}>
                  <Tukinowagamo></Tukinowagamo>
                </div>
              </AnimationOnScroll>
              <Typography component="h4" variant="h4" sx={{textAlign:"center", lineHeight: 3}} > 
                Moon Crescent History
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h5" color="text.secondary" paragraph>
              {p4}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <br />
        <Accordion sx={{backgroundColor:"#DAB8FF", color: "#000"}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Stack 
              sx={{textAlign: "center", margin:"auto"}}
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}>
              <AnimationOnScroll animateIn="animate__rubberBand">
                <SmoltingClassic/>
              </AnimationOnScroll>
              <Typography component="h4" variant="h4" sx={{textAlign:"center", lineHeight: 3}} > 
                Smolting/Inversebrah
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h5" color="text.secondary" paragraph>
            This frog-like wassie known as Smolting i.e Small Thing or inversebrah is the very soul of Crypto Twitter/X —a vessel for its eternal memory. He’s the record keeper of the biggest dramas, memes, and developments in crypto.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Container>
    </div>
  );
};

export default About;
