import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

interface Props {
  domain: string;
}

const Copyright = ({ domain }: Props) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}{new Date().getFullYear()}{' '}
      <Link color="inherit" href={"https://" + domain} target="blank">
        Wassie
      </Link>{'.'}
      {' '}
      All rights reserved.
    </Typography>
  );
};

export default Copyright;
