import SvgIcon from '@mui/material/SvgIcon';

const BaseLogo = () => {
  return (
    <SvgIcon sx={{ width: 35, height: 35 }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          fill="none"
          viewBox="0 0 32 110"
        >
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M71.046 55c0 30.376-24.675 55-55.113 55-28.878 0-52.568-22.165-54.921-50.377h72.847v-9.246h-72.847C-36.635 22.165-12.945 0 15.933 0c30.438 0 55.113 24.624 55.113 55z"
            clipRule="evenodd"
          />
        </svg>
    </SvgIcon>
  );
};
export default BaseLogo;
