import * as React from 'react';
import Button from '@mui/material/Button';
// import Box from '@mui/material/Box';
// import Modal from '@mui/material/Modal';
// import SolanaOrca from './SolanaOrca';
// import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

// const style = {
//   position: 'fixed' as 'fixed',
//   bottom: '0%',
//   right: '5px',
//   borderRadius: '10px 10px 0 0',
//   backgroundColor: "rgb(13, 17, 28)",
//   padding: "10px",
//   border: "none",
//   textAlign: "right", 
//   minWidth: 400,
//   maxWidth: "100vw",
// };

export default function SolanaOrcaModal() {
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);  

  return (
    <div>
      <Button 
        startIcon={<OpenInNewIcon />} 
        className="text-meme rounded-button" 
        sx={{ my: 2, color: 'white', bgcolor:"#00ffb2d9", fontSize:"1.5rem", pl:4, pr:4}} 
        // onClick={handleOpen}
        href="https://jup.ag/swap/SOL-WASSIE_2erZCPbQBobsFV23Z18J8aSfrV3QJ1RB8Ge9jhKjTL9m"
        target='blank'
        variant="contained" 
        size="large" 
        >
          Buy on Solana with Jupiter
      </Button>
      {/* <Modal
        sx={{position:"relative"}}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
          <Box sx={style}>
          <Button className="rounded-button" variant="outlined" startIcon={<OpenInNewIcon />} 
            href="https://jup.ag/swap/SOL-WASSIE_2erZCPbQBobsFV23Z18J8aSfrV3QJ1RB8Ge9jhKjTL9m"
            rel="noopener"
            target="blank">
              Go to Jupiter
          </Button>
          <Button className="rounded-button" variant="outlined" endIcon={<CloseIcon />} onClick={handleClose}>Close</Button>
          <hr/>
          <SolanaOrca />
        </Box>
      </Modal> */}
    </div>
  );
}