import React, { useState } from "react";
import Album from "./components/Main";
import "./App.scss";
import "animate.css/animate.min.css";
import { Analytics } from '@vercel/analytics/react';

function App() {
  const appName = "$WASSIE";
  const domain = "wassie.wtf";

  return (
    <div className="App">
        <Album appName={appName} domain={domain}></Album>
        <Analytics />
    </div>
  );
}

export default App;