import SvgIcon from '@mui/material/SvgIcon';

const EthereumLogo = () => {
  return (
    <SvgIcon sx={{ width: 35, height: 35 }}>
      <svg
        width="25"
        height="25"
        viewBox="0 0 50 50"
        version="1.1"
        id="svg6"
        xmlns="http://www.w3.org/2000/svg">
      <path
        id="path924"
        fill='#fff'
        d="M 24.683594 0.79101562 L 9.9980469 25.763672 L 24.6875 34.947266 L 39.371094 25.763672 L 24.683594 0.79101562 z M 39.351562 28.712891 L 24.699219 37.875 L 10.013672 28.71875 L 24.681641 49.734375 L 39.351562 28.712891 z " />
      </svg>
    </SvgIcon>
  );
};
export default EthereumLogo;
