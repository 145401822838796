import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const steps = [
  {
    label: 'Memes and Evolution ',
    description: `Embracing and evolving wassie memes.`,
  },
  {
    label: 'Wassification of Mankind',
    description:
      'Turning everything wassie and integrating wassie memes into human life.',
  },
  {
    label: 'Decentralized Exchange (DEX) Integration',
    description: `DEXes, L2s`,
  },
  {
    label: 'Centralized Exchange (CEX) Listings',
    description: `All tier of exchanges`,
  },
  {
    label: 'Cultural Ascendancy',
    description: `Elevate wassie to a transcendent status.`,
  },
];

export default function RoadmapStepper() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box className="rounded-button-outlined RoadmapBox" alignItems={"center"}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>
              <Typography className="text-meme" align="left" variant="h4" color="#fff" sx={{m:0}} paragraph>
                {step.label}
              </Typography>
            </StepLabel>
            <StepContent>
              <Typography variant="h5" color="text.secondary" paragraph>
                {step.description}
              </Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Is oke' : 'Read More'}
                  </Button>
                  {/* <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button> */}
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Button onClick={handleReset} variant="contained" sx={{ mt: 1, mr: 1 }}>
          Respawn
        </Button>
      )}
    </Box>
  );
}