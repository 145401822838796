import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MenuBar from "./MenuBar";
import Fab from '@mui/material/Fab';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import TelegramIcon from '@mui/icons-material/Telegram';
import Footer from './Footer';
import About from './About';
import Roadmap from './Roadmap';
import Contact from './Contact';
import Tokenomics from './Tokenomics';
import CopyToClipboard from './utils/CopyToClipboard';
import CmcLogo from './svg/CmcLogo';
import PlushieWassie from './svg/PlushieWassie';
import DextoolLogo from './svg/DextoolLogo';
import UniswapLogo from './svg/UniswapLogo';
import SmardexLogo from './svg/SmardexLogo';
import EtherscanLogo from './svg/EtherscanLogo';
import XLogo from './svg/XLogo';
import CoinGeckoLogo from './svg/CoinGeckoLogo';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import styled, { keyframes } from 'styled-components';
import { bounceInUp, fadeInUp, flipInX } from 'react-animations';
import HowToBuy from './HowToBuy';
import { Avatar, Grid, Link } from '@mui/material';
import RedditIcon from '@mui/icons-material/Reddit';

const bounceInUpAnimation = keyframes`${bounceInUp}`;
const fadeInUpAnimation = keyframes`${fadeInUp}`;
const flipInXAnimation = keyframes`${flipInX}`;


const BounceInUpDiv = styled.div`
  animation: 1s ${bounceInUpAnimation};
`;
const FadeInUpDiv = styled.div`
  animation: 1s ${fadeInUpAnimation};
`;
const FlipInDiv = styled.div`
  animation: 1s ${flipInXAnimation};
`;


const sections = ['Home', 'About', 'Tokenomics', 'Token Supply', 'Roadmap', "How to buy"];
const texts = [
  ", the smol ting that lives in your fridge",
  "The era of the Wassie memecoin has arrived.",
  "The first illustration appeared in 2016 on Twitter now X and was drawn by Japanese hentai artist Tukinowagamo. The wassie name and lore emerged in 2017 by user wasserpest and was formed through interactions between inversebrah and others.",
  "Wassies are tiny creatures with room temperature IQ. They are usually a mix of platypuses, frogs, ducks, or penguins and the result of radioactive incidents or mysterious apocalyptic events. Their short lifespan extends further if kept in a fridge after which they respawn with the knowledge of their ancestors.   ",
  "Some people derive amusement from targeting them, while others passionately stand up to their defense but wassies simply cannot be stopped or eradicated.",
  "Tukinowagamo’s secondary account was called Tsukinowa Gamo which means Ursus thibetanus (Tsukinowa) and duck (Gamo) in Japanese. In Japan, Ursus thibetanus is referred to as Tsukinowaguma, meaning 'moon' due to the moon-like crescent pattern on its chest.",
  "Global Wassification through $WASSIE utilizing the Potency of Memetic Energy",
  "Fairly Launched, No Taxes Forever, Max Buy Limit of 1% Per Wallet",
  "LP Tokens Burned and Contract Renounced",
  "0,000,000,000",
  "$WASSIE coin is not affiliated with Tukinowagamo but is a tribute to this beloved meme",
]

const wassieTheme = createTheme({
  typography: {
    fontFamily: [
      'Light',
      'Roboto',
      '"Helvetica Neue"',
      'sans-serif',
    ].join(','),
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#bb90e9',
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#25bc85',
      // light: '#F5EBFF',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#47008F',
    },
  },
});

interface Props {
  domain: string,
  appName: string
}


const Main = ({ domain, appName }: Props) => {

  return (
    <ThemeProvider theme={wassieTheme}>
      <CssBaseline />
      <MenuBar></MenuBar>
      <main>
        <Box
          id="smoltingBg"
          sx={{
            color: "white",
            bgcolor: '#bb90e9', 
          }}
        >   
            <Container id="BaseLine" maxWidth="xl">
              <Stack 
                  sx={{textAlign: "left"}}
                  direction={{ sm: 'column', md: 'row' }}>
                <Box sx={{ display: { sm: 'block', md: 'none' }, margin: "auto"}}>
                  <PlushieWassie/>
                </Box>
                <Grid container>
                  <Grid item>
                    <FadeInUpDiv>
                      <Typography className='text-meme main-title' component="h1" variant="h2" align="left" color="#fff" pt={2} pb={0}>
                        <span className='text-highlight'>{ appName }</span>{texts[0]}
                      </Typography>
                    </FadeInUpDiv>
                  </Grid>
                  <Grid item>
                  </Grid>
                  <Container id="bigWassieLine" maxWidth="xl">
                    <Stack 
                      sx={{textAlign: "left", margin:"auto", pl:0}}
                      direction={{ sm: 'column', md: 'row' }}>
                      <Container sx={{pb:9, pl:0, m:"auto"}} id="socials">                   
                        <BounceInUpDiv>
                          <Stack
                            sx={{ pt: 4}}
                            direction="row"
                            spacing={{ xs: 0.5, sm: 2, md: 2 }}
                            justifyContent={{ xs: "center", md: "left" }}
                            useFlexGap flexWrap="wrap"
                          >
                            <Tooltip title="X / Twitter">
                              <Fab className="socialIcon" href="https://twitter.com/WassieOnETH" rel="noopener" target="blank" color="primary" sx={{marginRight: 0.8, backgroundColor:"black"}} aria-label="twitter" size="large">
                                <XLogo />
                              </Fab>
                            </Tooltip>
                            <Tooltip title="Telegram">
                              <Fab className="socialIcon" href="https://t.me/WassieOnETH" rel="noopener" target="blank" color="primary" sx={{marginRight: 0.8, backgroundColor:"#0088cc", color:"#fff"}} aria-label="telegram" size="large">
                                <TelegramIcon sx={{ width: 50, height: 50 }} />
                              </Fab>
                            </Tooltip>
                            {/* <Tooltip title="Reddit">
                              <Fab className="socialIcon" href="https://www.reddit.com/r/WASSIE/" rel="noopener" target="blank" color="primary" sx={{marginRight: 0.8, backgroundColor:"#FF4500"}} aria-label="edit" size="large">
                              <RedditIcon sx={{ color: "white", width: 55, height: 55 }} />
                              </Fab>
                            </Tooltip> */}
                            <Tooltip title="Uniswap">
                              <Fab className="socialIcon" href="https://app.uniswap.org/#/swap?outputCurrency=0x2c95d751da37a5c1d9c5a7fd465c1d50f3d96160" rel="noopener" target="blank" size="large" color="primary" sx={{marginRight: 0.8, backgroundColor:"#ff007a"}} aria-label="edit">
                                <UniswapLogo/>
                              </Fab>
                            </Tooltip>
                            <Tooltip title="CoinMarketCap">
                              <Fab className="socialIcon" href="https://coinmarketcap.com/currencies/wassie/" rel="noopener" target="blank" color="primary" sx={{marginRight: 0.8, backgroundColor:"#3861FB"}} aria-label="edit" size="large">
                                <CmcLogo></CmcLogo>
                              </Fab>
                            </Tooltip>
                            <Tooltip title="CoinGecko">
                              <Fab className="socialIcon" href="https://www.coingecko.com/en/coins/wassie" rel="noopener" target="blank" color="primary" sx={{marginRight: 0.8, backgroundColor:"#8bc53f"}} aria-label="edit" size="large">
                                <CoinGeckoLogo/>
                              </Fab>
                            </Tooltip>
                            <Tooltip title="Smardex">
                              <Fab className="socialIcon" 
                                  href="https://smardex.io/swap?fromToken=0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee&toToken=0x2c95d751da37a5c1d9c5a7fd465c1d50f3d96160&fromChainId=1&toChainId=1" 
                                  rel="noopener" target="blank" color="primary" 
                                  sx={{marginRight: 0.8, backgroundColor:"#0C262C"}} aria-label="edit" size="large">
                                <SmardexLogo />
                              </Fab>
                            </Tooltip>
                            <Tooltip title="Dextools">
                              <Fab className="socialIcon" href="https://www.dextools.io/app/en/ether/pair-explorer/0x9faf7ad8711f6b87cfcf39c235b0a03ef4bdd7c2" rel="noopener" target="blank" color="primary" sx={{marginRight: 0.8, backgroundColor:"#00b8d8"}} aria-label="edit" size="large">
                                <DextoolLogo/>
                              </Fab>
                            </Tooltip>
                          </Stack>
                        </BounceInUpDiv>
                        <FlipInDiv>
                        <Typography textAlign={{ xs: "center", md: "left" }} sx={{ pt:6 }} align="left" color="#000">
                            <Chip 
                              sx={{
                                fontSize: "1.3rem",
                                color: "#fff",
                                pl: 1
                              }}
                              icon={<EtherscanLogo />} 
                              label="Contract: 0x2c95d751da37a5c1d9c5a7fd465c1d50f3d96160" 
                              component="a" 
                              target="blank"
                              rel="noopener"
                              href="https://etherscan.io/address/0x2c95d751da37a5c1d9c5a7fd465c1d50f3d96160" 
                              clickable
                              /><CopyToClipboard/>
                            <br />
                            <Chip 
                              sx={{
                                fontSize: "1.3rem",
                                color: "#fff",
                                pl: 0.2,
                                pr: 0.2,
                                mr: 1,
                                padding:"auto"
                              }}
                              label=" Audit" 
                              icon={<VerifiedUserIcon color="inherit"/>} 
                              component="a" 
                              target="blank"
                              rel="noopener"
                              href="https://github.com/TechRate/Smart-Contract-Audits/blob/main/October_2023/WASSIE.pdf" 
                              clickable
                            />
                            <Link href="https://github.com/TechRate/Smart-Contract-Audits/blob/main/October_2023/WASSIE.pdf" rel="noopener" target="blank" sx={{color: "white", textDecoration: "underline"}} >
                              Details
                            </Link>
                          </Typography>
                        </FlipInDiv>
                      </Container>
                    </Stack>
                  </Container>
                </Grid>
                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, margin: "auto"}}>
                  <PlushieWassie />
                </Box>
              </Stack>
            </Container>
        </Box>


        <Box
          sx={{
            position:"relative",
            zIndex: 9
          }}
        >   
          <About title={sections[1]} p1={texts[2]} p2={texts[3]} p3={texts[4]} p4={texts[5]}></About>
          <Tokenomics title={ sections[2] } p1={ texts[7] } p2={ texts[8] }></Tokenomics>
          <HowToBuy title={sections[5]}></HowToBuy>
          <Roadmap title={ sections[4] } content={ texts[6] }></Roadmap>
          <Contact />
        </Box>
      </main>
    <Footer domain={domain} description={texts[10]}></Footer>
    </ThemeProvider>
  );
}

export default Main;