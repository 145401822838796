import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const style = {
  border: "none", 
  zIndex: 2,
};

const Flooz = () => {

  const [spin, setSpinner] = React.useState(true);
  const closeSpinner = () => setSpinner(false);

  return (
    <div>
      {spin ? (
          <CircularProgress className="spinner" color="primary" />
        ) : null}

      {/* Your referral code is: mUXJpf */}
      <iframe 
        loading="lazy"
        onLoad={closeSpinner}
        style={style}
        width="100%" height="560px" allow="clipboard-read *; clipboard-write *; web-share *; accelerometer *; autoplay *; camera *; gyroscope *; payment *; geolocation *" src="https://flooz.xyz/embed/trade?swapDisabled=true&swapLockToToken=false&onRampDisabled=false&onRampAsDefault=false&onRampTokenAddress=0x2c95D751DA37A5C1d9c5a7Fd465c1d50F3d96160&onRampLockToken=true&stakeDisabled=true&network=eth&lightMode=false&primaryColor=%23bb90e9&backgroundColor=transparent&roundedCorners=10&padding=20&refId=mUXJpf" >
      </iframe>
    </div>
  );
};
export default Flooz;
