import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Uniswap from './Uniswap';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const style = {
  position: 'fixed' as 'fixed',
  bottom: '0%',
  right: '5px',
  borderRadius: '10px 10px 0 0',
  backgroundColor: "rgb(13, 17, 28)",
  padding: "10px",
  border: "none",
  textAlign: "right", 
  minWidth: 400,
  maxWidth: "100vw",
};

export default function BaseUniswapModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button 
        className="text-meme rounded-button" 
        sx={{ my: 2, color: 'white', bgcolor:"#e415b8", display: 'block', fontSize:"1.5rem", pl:4, pr:4}} 
        onClick={handleOpen} 
        variant="contained" 
        size="large" 
        >
          Swap on Uniswap
      </Button>
      <Modal
        sx={{position:"relative"}}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
          <Box sx={style}>
          <Button className="rounded-button" variant="outlined" startIcon={<OpenInNewIcon />} 
            href="https://app.uniswap.org/#/swap?chain=mainnet&outputCurrency=0x2c95d751da37a5c1d9c5a7fd465c1d50f3d96160"
            rel="noopener"
            target="blank">
              Go to Uniswap
          </Button>
          <Button className="rounded-button" variant="outlined" endIcon={<CloseIcon />} onClick={handleClose}>Close</Button>
          <hr/>
          <Uniswap></Uniswap>
        </Box>
      </Modal>
    </div>
  );
}