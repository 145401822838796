import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const style = {
  border: "none", 
  zIndex: 2,
};

const Uniswap = () => {

  const [spin, setSpinner] = React.useState(true);
  const closeSpinner = () => setSpinner(false);

  return (
    <div>
      {spin ? (
          <CircularProgress className="spinner" color="primary" />
        ) : null}
      
      <iframe
        loading="lazy"
        onLoad={closeSpinner}
        src="https://app.uniswap.org/#/swap?chain=base&outputCurrency=0xa067436db77ab18b1a315095e4b816791609897c"
        height="560px"
        width="100%"
        style={style}
      />
    </div>
  );
};
export default Uniswap;
