import  { Stack, Tooltip, Container, Typography, Fab, Link} from '@mui/material';
import XLogoInverted from './svg/XLogoInverted';
import Monalisa from './svg/Monalisa';
import TelegramIcon from '@mui/icons-material/Telegram';
import RedditIcon from '@mui/icons-material/Reddit';

const style = {
  backgroundColor: "#bb90e9",
}

const Contact = () => {
  return (
    <div style={style}>
      <hr className="blurred" />
      <Container id="smoltingCoffee" sx={{ py: 8, m: "auto"}}>
        <Typography
            id="__CONTACT"
            className='text-meme title-section'
            component="h2"
            variant="h1"
            align="center"
            color="#fff"
            fontFamily={"Londrina"}
          >
            Contact us
        </Typography>
        <Stack
          sx={{ pt: 4, mb:5}}
          direction="row"
          spacing={{ xs: 0.5, sm: 2, md: 2 }}
          justifyContent="center"
          useFlexGap flexWrap="wrap"
        >
          <Tooltip title="X / Twitter">
            <Fab className="socialIcon" 
            href="https://twitter.com/WassieOnETH" 
            rel="noopener" 
            target="blank" 
            color="primary" 
            sx={{backgroundColor:"#fff", boxShadow:"none", border: "1px solid black"}} 
            aria-label="twitter" size="medium">
              <XLogoInverted /> 
            </Fab>
          </Tooltip>
          <Tooltip title="Telegram">
            <Fab className="socialIcon" 
            href="https://t.me/WassieOnETH" 
            rel="noopener" 
            target="blank" 
            color="primary" 
            sx={{backgroundColor:"#fff", color:"#bb90e9", boxShadow:"none", border: "1px solid black"}} 
            aria-label="telegram" 
            size="medium">
              <TelegramIcon sx={{ width: 50, height: 50 }} />
            </Fab>
          </Tooltip>
          {/* <Tooltip title="Reddit">
            <Fab className="socialIcon" 
            href="https://www.reddit.com/r/WASSIE/" 
            rel="noopener" 
            target="blank"
            color="primary" 
            sx={{backgroundColor:"#fff", color:"#bb90e9", boxShadow:"none", border: "1px solid black"}} 
            aria-label="Reddit" 
            size="medium">
              <RedditIcon sx={{ width: 50, height: 50 }} />
            </Fab>
          </Tooltip> */}
        </Stack>
        <Typography
          className="text-meme"
          variant="h3" 
          color="#fff"
          textAlign="center"
          paragraph>
          Shh is oke, join us
          <Typography variant='h4' className="text-meme" mt={5}>
            <Link sx={{textDecoration: "none", color: "white"}} href="mailto:wassieoneth@gmail.com">wassieoneth@gmail.com</Link><br />
            <Link sx={{textDecoration: "none", color: "white"}} href="mailto:wassieoneth@ask-wassie.com">wassieoneth@ask-wassie.com</Link>
          </Typography>
          <div style={{ margin:"auto", borderRadius: 0, marginTop:"1em", marginBottom:"3em", color: "#000"}}>
            <Monalisa></Monalisa>
          </div>
        </Typography>
      </Container>
    </div>
  );
};

export default Contact;
