import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const style = {
  border: "none", 
  zIndex: 2,
};

const Uniswap = () => {

  const [spin, setSpinner] = React.useState(true);
  const closeSpinner = () => setSpinner(false);

  return (
    <div>
      {spin ? (
          <CircularProgress className="spinner" color="primary" />
        ) : null}
      
      <iframe
        loading="lazy"
        onLoad={closeSpinner}
        src="https://app.uniswap.org/#/swap?chain=mainnet&outputCurrency=0x2c95d751da37a5c1d9c5a7fd465c1d50f3d96160"
        height="560px"
        width="100%"
        style={style}
      />
    </div>
  );
};
export default Uniswap;
