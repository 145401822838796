import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Copyright from "./Copyright";
import OgWassie from './svg/OgWassie';
import SmoltingBaphie from './svg/SmoltingBaphie';

interface Props {
  domain: string;
  description: string;
}

const Footer = ({ domain, description}: Props) => {
  return (
      <Box sx={{ bgcolor: 'background.paper', p: 6, mt:5}} component="footer">
        <Container maxWidth="xs">
          <Stack direction={{ xs: 'column', sm: 'row' }}>
            {/* <span style={{textAlign: "center", margin:"auto", marginBottom: "2em"}}>
              <SmoltingBaphie></SmoltingBaphie>
            </span> */}
            <span style={{textAlign: "center", margin:"auto", marginBottom: "2em"}}>
              <OgWassie></OgWassie>
            </span>
          </Stack>
        </Container>
        <Typography variant="h5" align="center" color="text.secondary" sx={{pb:5}} gutterBottom>
          {description}
        </Typography>
        <Copyright domain={domain}></Copyright>
      </Box>
  );
};

export default Footer;
