import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import RoadmapStepper from './RoadmapStepper';
import WassieHandsUp from './svg/WassieHandsUp';

interface Props {
  title: string;
  content: string;
}

const style = {
  backgroundColor: "#bb90e9",
}

const Roadmap = ({ title, content }: Props) => {
  return (
    <div style={style}>
      <hr className="blurred" />
      <Container sx={{ pt: 8, pb: 2 }}>
        <Typography
          id="__ROADMAP"
          className='text-meme title-section'
          component="h2"
          variant="h1"
          align="center"
          color="#fff"
          fontFamily={"Londrina"}
          gutterBottom
        >
          { title }
        </Typography>
        <AnimationOnScroll animateIn="animate__bounceInUp">
          <Typography className="text-meme" variant="h4" align="center" color="#fff" paragraph>
            { content }
          </Typography>
          <RoadmapStepper></RoadmapStepper>
        </AnimationOnScroll>
      </Container>
      <div style={{textAlign: "center", margin:"auto"}}>
        <WassieHandsUp></WassieHandsUp>
      </div>
    </div>
  );
};

export default Roadmap;
