import SvgIcon from '@mui/material/SvgIcon';

const DextoolLogo = () => {
  return (
    <SvgIcon sx={{ width: 50, height: 50 }}>
      <svg
        fill="white"
        width="29"
        height="29"
        viewBox="0 0 48 48"
        version="1.1"
        id="svg5"
        xmlns="http://www.w3.org/2000/svg"
        >
        <defs
          id="defs2" />
        <g
          id="layer1">
          <g
            id="g2355"
            transform="matrix(1.2505702,0,0,1.2505702,-3.9673114,-5.29636)">
            <path
              d="m 5.9717542,21.713405 4.5536748,-2.221557 -0.08218,-5.783715 5.748779,2.849079 4.617761,-2.226687 L 10.453721,9.1860596 6.0156692,11.388293 Z"
              id="path982" />
            <path
              d="m 32.465293,18.509842 -4.553675,2.221558 0.08218,5.783715 -5.748779,-2.849079 -4.617761,2.226687 10.356068,5.144465 4.438052,-2.202233 z"
              id="path1698" />
            <path
              d="m 17.797609,30.192199 1.409889,0.894109 1.433343,-0.863512 4.575989,2.275339 -6.06422,2.979987 -5.719383,-3.049614 z"
              id="path1813" />
            <path
              d="m 20.600165,10.101783 -1.409889,-0.8941089 -1.433343,0.8635119 -4.575989,-2.2753389 6.06422,-2.979987 5.719383,3.049614 z"
              id="path1895" />
            <path
              d="m 23.624203,11.490703 4.536324,-2.2973697 4.301154,2.2807997 0.0021,4.384486 -21.973763,10.810137 4.379809,2.140725 -4.545709,2.340071 -4.3333236,-2.214372 0.0088,-4.49636 21.9733766,-10.792188 z"
              id="path2010" />
          </g>
        </g>
      </svg>
      </SvgIcon>
  );
};
export default DextoolLogo;