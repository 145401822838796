import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const style = {
  border: "none", 
  zIndex: 2,
};

const Smardex = () => {

  const [spin, setSpinner] = React.useState(true);
  const closeSpinner = () => setSpinner(false);

  return (
    <div>
      {spin ? (
          <CircularProgress className="spinner" color="primary" />
        ) : null}    
      <iframe 
        src="https://smardex.io/embed/swap?chainId=1&tokenA=0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee&tokenB=0x2c95d751da37a5c1d9c5a7fd465c1d50f3d96160" 
        style={style} 
        onLoad={closeSpinner}
        name="smardex-swap" 
        title="smardex-swap" 
        width="100%" height="560px" allow="clipboard-read *; clipboard-write *; web-share *; accelerometer *; autoplay *; camera *; gyroscope *; payment *; geolocation *"></iframe>
    </div>
  );
};
export default Smardex;
