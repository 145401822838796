import { Grid, Box, Stack, Container, Link, Typography } from '@mui/material';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import TokenomicsLogo from './svg/TokenomicsLogo';

interface Props {
  title: string;
  p1: string;
  p2: string;
}

const style = {
  backgroundColor: "#bb90e9",
}


const Tokenomics = ({ title, p1, p2 }: Props) => {
  return (
    <div style={{backgroundColor: "#bb90e9"}}>
      <hr className="blurred" />
      <Container  maxWidth="xl" id="bigWassieBg" sx={{ pt: 8, color: "#fff", pb: 5}}>
        <Typography
          id="__TOKENOMICS"
          className='text-meme title-section'
          component="h2"
          variant="h1"
          align="center"
          color="#fff"
          pb={5}
          fontFamily={"Londrina"}
          gutterBottom
        >
          { title }
        </Typography>
        <Stack direction={{ sm: 'column', md: 'row' }}>
          <Box
            className="rounded-button-outlined tokenomicsBox">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} pb={4} sx={{color: "#000", textAlign:{ xs: 'center', sm: 'right' }}}>
                <AnimationOnScroll animateIn="animate__bounceInLeft">
                    <TokenomicsLogo></TokenomicsLogo>
                </AnimationOnScroll>
              </Grid>
              <Grid item xs={12} sm={6}>
                <AnimationOnScroll animateIn="animate__bounceInRight">
                  <Box>
                    <Typography
                      id="__TOKENSUPPLY"
                      className='text-meme'
                      component="h3"
                      variant="h4"
                      textAlign={{ xs: "center", md: "left" }} 
                      color="#fff"
                      mt={2}
                      fontFamily={"Londrina"}
                      gutterBottom
                    >
                      Token Supply
                    </Typography>
                    <Typography className="text-meme" variant="h4" textAlign={{ xs: "center", md: "left" }}  color="#fff" paragraph>
                      690,000,000,000
                    </Typography>
                  </Box>
                </AnimationOnScroll>
              </Grid>
              
            </Grid>
            <Typography variant="h5" textAlign={{ xs: "center", md: "center" }}  color="text.secondary" paragraph>
                {p1}
            </Typography>
            <Typography variant="h5" textAlign={{ xs: "center", md: "center" }}  color="text.secondary" paragraph>
                {p2}
            </Typography>
            {/* <Typography variant="h5" textAlign={{ xs: "center", md: "center" }}  color="text.secondary" paragraph>
                Supply acquired on the market held in the multi-sig wallet 
                <br />
                <Link 
                  href="https://etherscan.io/address/0x105B1802f56B7084894c468ca4D9a86fbADc38e8" 
                  rel="noopener" 
                  target="blank" 
                  sx={{color:"white", wordWrap: "break-word"}}>
                 0x105B1802f56B7084894c468ca4D9a86fbADc38e8 
                </Link><br />
                {' '}for future centralized exchange listings.
            </Typography> */}

          </Box>
          <Box sx={{width:"25%"}}></Box>
        </Stack>
      </Container>
    </div>
  );
};

export default Tokenomics;
