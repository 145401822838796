import SvgIcon from '@mui/material/SvgIcon';

const BaseLogo = () => {
  return (
    <SvgIcon sx={{ width: 35, height: 35 }}>
         <svg
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          fill="none"
        >
          <path
            fill="#fff"
            d="m23.854 18.056-3.958 4.136a.92.92 0 0 1-.673.284H.46a.468.468 0 0 1-.252-.073.437.437 0 0 1-.084-.68l3.962-4.136a.92.92 0 0 1 .67-.284h18.763a.453.453 0 0 1 .422.27.438.438 0 0 1-.086.483zm-3.958-8.328a.92.92 0 0 0-.673-.284H.46a.468.468 0 0 0-.252.073.437.437 0 0 0-.084.68l3.962 4.136a.92.92 0 0 0 .67.284h18.763a.453.453 0 0 0 .422-.27.438.438 0 0 0-.086-.483l-3.958-4.136zM.46 6.758h18.764a.94.94 0 0 0 .672-.285l3.958-4.135a.444.444 0 0 0 .086-.484.453.453 0 0 0-.17-.196.469.469 0 0 0-.252-.073H4.755a.94.94 0 0 0-.67.284L.125 6.005a.443.443 0 0 0-.086.483.45.45 0 0 0 .17.196.468.468 0 0 0 .25.074z"
          />
        </svg>
    </SvgIcon>
  );
};
export default BaseLogo;
