import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const style = {
  border: "none", 
  zIndex: 2,
};

const BaseSmardex = () => {

  const [spin, setSpinner] = React.useState(true);
  const closeSpinner = () => setSpinner(false);

  return (
    <div>
      {spin ? (
          <CircularProgress className="spinner" color="primary" />
        ) : null}    
      <iframe 
        src="https://smardex.io/embed/swap?chainId=8453&tokenA=0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee&tokenB=0xa067436db77ab18b1a315095e4b816791609897c" 
        style={style} 
        onLoad={closeSpinner}
        name="smardex-swap" 
        title="smardex-swap" 
        width="100%" height="560px" allow="clipboard-read *; clipboard-write *; web-share *; accelerometer *; autoplay *; camera *; gyroscope *; payment *; geolocation *"></iframe>
    </div>
  );
};
export default BaseSmardex;
