import SvgIcon from '@mui/material/SvgIcon';

const CmcLogo = () => {
  return (
    <SvgIcon sx={{ width: 50, height: 50 }}>
      <svg
        width="29"
        height="29"
        fill="white"
        viewBox="0 0 35 35"
        className="cmc-logo-img"
        version="1.1"
        id="svg4"
        xmlns="http://www.w3.org/2000/svg">
        <defs
          id="defs8" />
        <path
          d="M 28.442,14.445 V 14.389 C 28.414,6.466 22.032,0 14.221,0 6.41,0 0,6.466 0,14.445 c 0,7.98 6.381,14.433 14.221,14.433 3.59752,0.0024 7.057473,-1.38225 9.66,-3.866 1.288666,-1.177333 -0.477334,-3.110333 -1.766,-1.933 l -0.028,0.028 C 17.351545,27.608771 9.8214968,27.265711 5.515,22.352 L 11.59,12.61 v 4.508 c 0,2.154 0.84,2.855 1.546,3.051 0.706,0.196 1.765,0.054 2.912,-1.765 l 3.333,-5.412 c 0.089,-0.158 0.192,-0.308 0.306,-0.449 v 2.745 c 0,2.015 0.812,3.639 2.211,4.422 1.315763,0.716425 2.918695,0.652277 4.173,-0.167 1.616,-1.049 2.484,-2.886 2.371,-5.098 z m -3.696,2.835 c -0.457005,0.314815 -1.048708,0.357298 -1.546,0.111 -0.56,-0.335 -0.897,-1.09 -0.897,-2.126 v -3.173 c 0,-1.51 -0.588,-2.603 -1.595,-2.881 -1.709,-0.516 -2.995,1.595 -3.472,2.379 l -3.015,4.842 V 10.47 C 14.193,9.099 13.744,8.287 12.904,8.034 12.344,7.867 11.504,7.952 10.693,9.184 L 3.946,19.989 C 3.0484244,18.27867 2.5780394,16.376544 2.575,14.445 2.575,7.922 7.809,2.631 14.221,2.631 c 6.412,0 11.646,5.291 11.646,11.814 v 0.057 c 0.067,1.258 -0.337,2.268 -1.12,2.77 v 0.008 z"
          id="path2"/>
      </svg>
    </SvgIcon>
  );
};
export default CmcLogo;
