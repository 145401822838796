import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UniswapLogoAlt from './svg/UniswapLogoAlt';
import EthereumLogo from './svg/EthereumLogo';
import MetamaskLogo from './svg/MetamaskLogo';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import FloozModal from './FloozModal';
import FloozLogo from './svg/FloozLogo';
import BaseLogo from './svg/BaseLogo';
import SolanaLogo from './svg/SolanaLogo';
import BaseSmardexModal from './BaseSmardexModal';
import BaseUniswapModal from './BaseUniswapModal';
import UniswapModal from './UniswapModal';
import SmardexModal from './SmardexModal';
import SolanaOrcaModal from './SolanaOrcaModal';


interface Props {
  title: string;
}

const style = {
  backgroundColor: "#bb90e9",
}

const HowToBuy = ({ title}: Props) => {
  return (
    <div style={style}>
      <hr className="blurred" />
      <Container sx={{ py: 8 }}>
        <Typography
          id="__HOW%20TO%20BUY"
          className='text-meme title-section'
          component="h2"
          variant="h1"
          align="center"
          color="#fff"
          fontFamily={"Londrina"}
          gutterBottom
        >
          { title }
        </Typography>
        <AnimationOnScroll animateIn='animate__bounceInUp'>

          <Container sx={{width: { xs: "100%", sm: "90%", md: '80%' }}}>
            <Accordion sx={{backgroundColor:"#DAB8FF", color: "#000", pt: 2, pb:2}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography component="h4" variant="h4">
                    <IconButton color="primary" sx={{mr:2, backgroundColor:"#fff"}} size="large">
                      <MetamaskLogo />
                    </IconButton>
                    Install Metamask
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="h4" color="text.secondary" paragraph>
                    Install Metamask Extension and Create an Account at <a href="https://metamask.io/" rel="noopener" target="_blank">MetaMask.io</a>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            <Accordion sx={{backgroundColor:"#DAB8FF", color: "#000", pt: 2, pb:2}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography component="h4" variant="h4">
                  <IconButton color="primary" sx={{mr:2, backgroundColor:"#5f5fd3"}} size="large">
                    <EthereumLogo />
                  </IconButton>
                  Add some ETH
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="h4" color="text.secondary" paragraph>
                  Make sure your Account has some ETH, you can buy ETH directly on MetaMask if needed.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{backgroundColor:"#DAB8FF", color: "#000", pt: 2, pb:2}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography component="h4" variant="h4">
                  <IconButton color="primary" sx={{mr:2, backgroundColor:"#ff007a"}} size="large">
                    <UniswapLogoAlt />
                  </IconButton>
                  Swap ETH for WASSIE
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="h4" color="text.secondary" paragraph>
                On either <a 
                href="https://app.uniswap.org/#/swap?outputCurrency=0x2c95d751da37a5c1d9c5a7fd465c1d50f3d96160" rel="noopener" target="_blank">
                Uniswap</a> or <a 
                href="https://smardex.io/swap?chainId=1&tokenA=0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee&tokenB=0x2c95d751da37a5c1d9c5a7fd465c1d50f3d96160" rel="noopener" target="_blank">
                Smardex</a>, connect MetaMask. Then, <br />
                <Breadcrumbs aria-label="breadcrumb">
                  <Typography variant="h4" paragraph>
                    Select Token
                  </Typography>
                  <Typography variant="h4" paragraph>
                    ETH for WASSIE
                  </Typography>
                  <Typography variant="h4" paragraph>
                    and Swap
                  </Typography>
                </Breadcrumbs>
                Use the right amount of slippage, if transaction doesn’t work increase gas on MetaMask or slippage.
                </Typography>
                <Stack sx={{margin: "auto"}} direction="column">
                  <Box>
                    <SmardexModal/>
                  </Box>
                  <Box>
                    <UniswapModal/>
                  </Box>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Container>
          <Container sx={{mt: 3, width: { xs: "100%", sm: "90%", md: '80%', pb: "20px"}}}>
          <Typography
            className='text-meme text-title'
            component="h4"
            variant="h4"
            align="center"
            color="#fff"
            gutterBottom
          >
            More options
          </Typography>
            {/* <Typography component="h5" variant="h5">
            Alternatively, you can also
            </Typography> */}
          </Container>
          <Container sx={{width: { xs: "100%", sm: "90%", md: '80%', pb: "20px"}}}>
            <Accordion sx={{backgroundColor:"#DAB8FF", color: "#000", pt: 2, pb:2}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography component="h4" variant="h4">
                  <IconButton color="primary" sx={{mr:2, backgroundColor:"#0052ff", color:"#000"}} size="large">
                    <BaseLogo/>
                  </IconButton>
                  Buy on Base
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="h4" color="text.secondary" paragraph>
                  Ensure your wallet is funded with ETH on Base Network <a target="blank" href="https://bridge.base.org/deposit">bridge.base.org</a>
                </Typography>
                <Typography variant="h4" color="text.secondary" paragraph>
                  On either Uniswap or Smardex, select Base network and locate the token using the address: <a href="https://basescan.org/address/0xa067436db77ab18b1a315095e4b816791609897c" target='blank' style={{wordBreak: "break-all"}}>0xa067436db77ab18b1a315095e4b816791609897c</a>
                </Typography>
                <Typography variant="h4" color="text.secondary" paragraph>
                  Choose the desired amount and proceed to swap.
                </Typography>
                <Stack sx={{margin: "auto"}} direction="column">
                  <Box>
                    <BaseSmardexModal/>
                  </Box>
                  <Box>
                    <BaseUniswapModal/>
                  </Box>
                </Stack>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{backgroundColor:"#DAB8FF", color: "#000", pt: 2, pb:2}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography component="h4" variant="h4">
                  <IconButton color="primary" sx={{mr:2, backgroundColor:"#9945FF", color:"#000"}} size="large">
                    <SolanaLogo/>
                  </IconButton>
                  Buy on Solana
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="h4" color="text.secondary" paragraph>
                  Ensure your wallet (ex: Phantom) is funded with SOL on Solana Network.
                </Typography>
                <Typography variant="h4" color="text.secondary" paragraph>
                  Connect to <a href="https://orca.so" target='blank'>orca.so</a> or any aggregator on Solana and specify this contract address <span style={{wordBreak: "break-all", fontWeight:"600"}}>2erZCPbQBobsFV23Z18J8aSfrV3QJ1RB8Ge9jhKjTL9m</span>
                </Typography>
                <Typography variant="h4" color="text.secondary" paragraph>
                  Choose the desired amount and proceed to swap.
                </Typography>
                <Stack sx={{margin: "auto"}} direction="column">
                  <Box>
                    <SolanaOrcaModal/>
                  </Box>
                </Stack>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{backgroundColor:"#DAB8FF", color: "#000", pt: 2, pb:2}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography component="h4" variant="h4">
                  <IconButton color="primary" sx={{mr:2, backgroundColor:"#25bc85", color:"#fff"}} size="large">
                    <CreditCardIcon sx={{ width: 35, height: 35 }}/>
                  </IconButton>
                  Buy with credit card
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="h4" color="text.secondary" paragraph>
                  You can also use our partner Flooz and buy $WASSIE with credit card.
                </Typography>
                <Stack direction={{ xs: 'column', sm: 'row' }}>
                  <Box>
                    <FloozModal/>
                  </Box>
                  <Link href="https://flooz.xyz" rel="noopener" target="blank" >
                    <FloozLogo/>
                  </Link>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Container>
        </AnimationOnScroll>

        {/* <Typography className="text-meme" variant="h4" align="right" color="#fff" paragraph>
          { p1 }
        </Typography> */}
      </Container>
    </div>
  );
};

export default HowToBuy;
