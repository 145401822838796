import SvgIcon from '@mui/material/SvgIcon';

const SmardexLogo = () => {
  return (
    <SvgIcon sx={{ width: 50, height: 50 }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 90 102"
        fill="none"
      >
        <g clipPath="url(#a)">
          <path
            fill="#fff"
            d="M86 26.63c0-1.55-.83-2.98-2.17-3.76L45.16.58a4.347 4.347 0 0 0-4.33 0L2.19 22.9A4.3 4.3 0 0 0 0 26.65v44.62c0 1.54.83 2.96 2.17 3.73l38.66 22.34c1.34.78 2.99.78 4.33 0L83.83 75A4.327 4.327 0 0 0 86 71.27V26.63ZM73.9 64.28A4.332 4.332 0 0 1 71.73 68L45.16 83.37c-1.34.78-2.99.78-4.33 0L14.27 68a4.355 4.355 0 0 1-2.17-3.76V52.83L40.83 69.4c1.34.77 3 .77 4.34 0l10.57-6.1V49.35l-9.86 5.73c-1.35.78-3.01.78-4.36 0l-17.31-10-12.09-7v-4.44c0-1.55.83-2.98 2.17-3.76l26.54-15.33c1.34-.77 3-.77 4.34 0l26.54 15.31c1.34.78 2.17 2.21 2.17 3.76v11.47l-12.1-7-16.61-9.57a4.363 4.363 0 0 0-4.35 0L30.3 34.61h-.07v14l9.85-5.72c1.34-.79 3.01-.79 4.35 0l17.36 10v-.08l12.11 7v4.47Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h86v97.92H0z" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
export default SmardexLogo;
